.App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 120%;
  color: #212D3F;
  opacity: 0.8;
  margin-bottom: 1.5em;
  margin-top: 4em;
}
a {
  text-decoration: none;
}
* {
  font-family: 'SF Pro Display', sans-serif;
}
