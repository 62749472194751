html, body {
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  background-color: rgb(248, 249, 251);
}
#root {
  width: 100%;
  height: 100%;
}
